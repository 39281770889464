<template>
  <div
    class="card"
    :class="{
      'card-expand': expanded,
      'card-tabs': tabs,
      border: noShadow,
      'no-shadow': noShadow,
    }"
  >
    <div v-if="!noHeader" class="card-header">
      <div v-if="title" class="card-title">{{ title }}</div>
      <slot name="header" />
      <ul v-if="actions" class="actions top-right align-items-center d-flex">
        <li>
          <slot name="top-header"></slot>
        </li>
        <dropdown v-if="filters.length" tag="li">
          <template #text>
            <i class="icon dripicons-experiment" />
          </template>
          <template #items>
            <form class="px-4 py-3" @submit.prevent="applyFilter">
              <template v-for="(filter, n) in filters">
                <h6 :key="n" class="dropdown-header">
                  {{ $tc(`app.${filter.name}`, 2) }}
                </h6>
                <template v-if="filter.type === 'checkbox'">
                  <div
                    v-for="option in filter.options"
                    :key="option.name"
                    class="form-group"
                  >
                    <div class="custom-control custom-checkbox form-check">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="option.label"
                        :value="option.name"
                        v-model="filter.value"
                      />
                      <label class="custom-control-label" :for="option.label">{{
                        $t(`app.${option.label}`)
                      }}</label>
                    </div>
                  </div>
                </template>
                <div
                  v-else-if="filter.type === 'select'"
                  :key="filter.name"
                  class="form-group"
                >
                  <select
                    class="form-control"
                    id="defaultSelect"
                    v-model="filter.value"
                  >
                    <option value=""></option>
                    <option
                      v-for="option in filter.options"
                      :key="option.name"
                      :value="{ label: option.label, value: option.name }"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
                <div
                  v-else-if="filter.type === 'text'"
                  :key="filter.name"
                  class="form-group"
                >
                  <input
                    type="text"
                    class="form-control"
                    v-model="filter.value"
                  />
                </div>
                <div
                  v-else-if="filter.type === 'range'"
                  :key="filter.name"
                  class="form-group"
                >
                  <date-range-picker
                    class="form-control"
                    v-model="filter.value"
                    :value="filter.value"
                    :options="options"
                  />
                </div>
              </template>
              <div class="dropdown-divider"></div>
              <button type="submit" class="btn btn-primary">
                {{ $t('app.search') }}
              </button>
            </form>
          </template>
        </dropdown>
        <li>
          <a href="javascript:void(0)" @click="reload"
            ><i class="icon dripicons-clockwise"></i
          ></a>
        </li>
        <!-- <li>
          <a href="javascript:void(0)" @click="expand">
            <i v-if="expanded" class="dripicons-contract-2"></i>
            <i v-else class="icon dripicons-expand-2"></i>
          </a>
        </li> -->
        <slot name="actions" />
        <dropdown v-if="exportAction" tag="li">
          <template #text>
            <i class="icon dripicons-cloud-download" />
          </template>
          <template #items>
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              @click="exportData('csv')"
              >CSV</a
            >
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              @click="exportData('xlsx')"
              >XLSX</a
            >
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              @click="exportData('pdf')"
              >PDF</a
            >
          </template>
        </dropdown>
      </ul>
    </div>
    <div class="card-body" :class="{ 'block-el': block }">
      <div v-if="filters" class="filters">
        <span
          v-for="(filter, n) in appliedFilters"
          :key="n"
          class="badge badge-light"
          ><b>{{ $tc(`app.${filter.name}`, 1) }}</b
          >&nbsp;
          <span v-if="filter.type == 'select'">{{ filter.value.label }}</span>
          <span v-else-if="filter.type != 'text'"
            ><span v-for="(val, i) in filter.value" :key="i"
              ><span v-if="i !== 0"> - </span>{{ val }}</span
            ></span
          >
          <span v-else>{{ filter.value }}</span>
          <a href="javascript:void(0)" @click="removeFilter(filter.name)">x</a>
        </span>
      </div>
      <div v-show="block && loading" class="qt-block-ui"></div>
      <slot name="body" />
    </div>
    <slot />
    <div v-if="footer" class="card-footer bg-light">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue';

export default {
  name: 'Card',
  components: {
    Dropdown,
  },
  props: {
    noHeader: Boolean,
    noShadow: Boolean,
    tabs: Boolean,
    title: String,
    block: Boolean,
    exportAction: String,
    search: {
      type: String,
      default: 'q',
    },
    actions: Boolean,
    filters: {
      type: Array,
      default() {
        return [];
      },
    },
    footer: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
          ],
        },
      },
      expanded: false,
      query: [],
      appliedFilters: {},
    };
  },
  created() {
    this.applyFilter();
  },
  methods: {
    applyFilter() {
      const query = [];
      this.filters?.forEach((element) => {
        if (element.type === 'checkbox') {
          if (!element.value.length) return;
          this.query[element.name] = {
            in: [],
          };
          element.value.forEach((value) => {
            this.query[element.name].in.push(value);
          });
        } else if (element.type === 'select') {
          this.query[element.name] = element.value.value;
        } else {
          this.query[element.name] = element.value;
        }
        if (element.value) {
          this.appliedFilters[element.name] = JSON.parse(
            JSON.stringify(element),
          );
        }
      });
      this.$emit('filter', this.query);
    },
    removeFilter(filterName = '') {
      this.filters?.forEach((filter) => {
        if (!filterName || filter.name === filterName) {
          filter.value = '';
        }
      });
      this.appliedFilters = {};
      this.applyFilter();
    },
    reload() {
      this.$emit('reload');
    },
    expand() {
      this.expanded = !this.expanded;
    },
    exportData(format) {
      this.$store
        .dispatch(this.exportAction, {
          ...this.query,
          export: format,
        })
        .then((response) => {
          const data = response.data;
          const name = response.headers['content-disposition'];
          const blob = new Blob([data], {
            type: response.headers['content-type'],
          });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, name);
          } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
              // feature detection
              // Browsers that support HTML5 download attribute
              const url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', name);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        });
      // this.$emit('export', format);
    },
  },
};
</script>

<style lang="scss" scoped>
.block-el {
  min-height: 100px;
}
.card-title {
  margin-bottom: 0;
  color: #101828 !important;
  font-weight: 600 !important;
  font-size: 20px !important;
}
.filters {
  text-align: right;
  .badge {
    margin-left: 5px;
    a {
      margin-left: 10px;
    }
  }
}

.dripicons-clockwise{
  color: #667085;
  font-size: 20px;
}
</style>
