<template>
  <div class="content block-el">
    <PageHeader :title="$t('admin.plan.create')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <form @submit.prevent="store">
            <card no-header footer>
              <template #body>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">
                        {{ $t('name') }}
                      </label>
                      <div class="col-sm-10">
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.name"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">
                        {{ $t('type') }}
                      </label>
                      <div class="col-sm-10">
                        <select
                          class="form-control"
                          v-model="form.service"
                          required
                          @change="changeService"
                        >
                          <option value="sms" selected>SMS</option>
                          <!-- <option value="omni">Omni Business</option> -->
                          <option value="omni">Chat</option>
                          <option value="whatsapp">Whatsapp API</option>
                          <option value="rcs">Google RCS</option>
                          <option value="email">E-Mail</option>
                          <option value="lookup">Lookup</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">
                        {{ $tc('app.model') }}
                      </label>
                      <div class="col-sm-10">
                        <select
                          class="form-control"
                          v-model="form.type"
                          required
                        >
                          <option value="per_use">
                            {{ $tc('app.per_use') }}
                          </option>
                          <option value="subscription">
                            {{ $tc('app.subscription') }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div v-if="form.service === 'omni'" class="col-6">
                    <div class="form-group row">
                      <div class="col-sm-2">{{ $tc('app.permission', 2) }}</div>
                      <div class="col-sm-10">
                        <div class="row">
                          <div
                            class="col-6"
                            v-for="(i, permission) in form.permissions"
                            :key="permission"
                          >
                            <div
                              class="custom-control custom-checkbox checkbox-primary m-b-20"
                            >
                              <input
                                v-model="form.permissions[permission]"
                                type="checkbox"
                                class="custom-control-input"
                                :id="permission"
                              />
                              <label
                                class="custom-control-label"
                                :for="permission"
                                >{{ $tc(`app.${permission}`, 2) }}</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #footer>
                <div class="form-group m-b-0 row">
                  <div class="col-sm-10">
                    <custom-button :loading="storing">
                      {{ $t('app.create') }}
                    </custom-button>
                  </div>
                </div>
              </template>
            </card>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import Card from '@/components/Card.vue';
import CustomButton from '@/components/CustomButton.vue';

export default {
  name: 'CreateNumber',
  components: {
    PageHeader,
    Card,
    CustomButton,
  },
  directives: {},
  data() {
    return {
      storing: false,
      form: {
        name: '',
        service: '',
        type: 'subscription',
        permissions: {},
      },
    };
  },
  created() {},
  methods: {
    changeService() {
      if (this.form.service === 'omni') {
        this.form.permissions = {
          department: false,
          opening_hour: false,
          contact_timeline: false,
          custom_field: false,
          shortcut: false,
          spie: false,
          tab: false,
          report: false,
          api: false,
          dashboard: false,
          automatic_distribution: false,
          history: false,
        };
      } else {
        this.form.permissions = {};
      }
    },
    store() {
      this.storing = true;
      this.$store
        .dispatch('storeAdminPlans', this.form)
        .then((response) => {
          this.$router.push({ name: 'AdminPlansIndex' });
        })
        .catch((ex) => {
          ex.response.data.errors.forEach((error) => {
            this.$toast.show({
              title: `Error ${error.code}`,
              content: error.detail,
              type: 'danger',
            });
          });
        })
        .finally(() => {
          this.storing = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
